import {
  LocalShippingOutlined,
  CurrencyExchangeOutlined,
  ReceiptOutlined,
  StorefrontOutlined,
} from '@mui/icons-material';

const environment = process.env.REACT_APP_ENVIRONMENT;

const baseURL =
  environment === 'production'
    ? process.env.REACT_APP_REST_URL_PROD
    : process.env.REACT_APP_REST_URL_DEV;

export const availableIntegrations = [
  {
    category: 'Transportadoras',
    icon: LocalShippingOutlined,
    options: [
      {
        name: 'Mandaê',
        iconSrc: '/assets/imgs/mandae.svg',
        configuration: {
          login: '',
          password: '',
          customerId: '',
          apiToken: '',
        },
      },
      {
        name: 'Melhor Envio',
        iconSrc: '/assets/imgs/melhorenvio.svg',
        configuration: {},
      },
      {
        name: 'Jadlog',
        iconSrc: '/assets/imgs/jadlog.svg',
        configuration: {},
      },
      {
        name: 'Correios',
        iconSrc: '/assets/imgs/correios.svg',
        configuration: {},
      },
      {
        key: 'jamefApi',
        name: 'Jamef',
        iconSrc: '/assets/imgs/jamef.svg',
        configuration: {
          username: '',
          password: '',
          email: '',
        },
      },
      {
        key: 'fmtApi',
        name: 'FM Transportes',
        iconSrc: '/assets/imgs/fm-transportes.svg',
        configuration: {
          username: '',
          password: '',
        },
      },
      {
        key: 'braspressApi',
        name: 'Braspress',
        iconSrc: '/assets/imgs/braspress.svg',
        configuration: {
          username: '',
          password: '',
        },
      },
      {
        key: 'rteApi',
        name: 'RTE Rodonaves',
        iconSrc: '/assets/imgs/rte.svg',
        configuration: {
          username: '',
          password: '',
        },
      },
      {
        name: 'Mark Ferragens',
        iconSrc: '/assets/imgs/mark.svg',
        configuration: {},
      },
    ],
  },
  {
    category: 'Marketplaces',
    icon: StorefrontOutlined,
    options: [
      {
        name: 'Mercado Livre',
        iconSrc: '/assets/imgs/mercadolivre.svg',
        authorizationUrl: `${baseURL}/mercado-livre/app/authorization`,
        configuration: {},
      },
      {
        name: 'Magazine Luiza',
        iconSrc: '/assets/imgs/magalu.svg',
        configuration: {},
      },
      {
        name: 'Americanas',
        iconSrc: '/assets/imgs/americanas.svg',
        configuration: {},
      },
      {
        name: 'Madeira Madeira',
        iconSrc: '/assets/imgs/madeiramadeira.svg',
        configuration: {},
      },
      {
        name: 'Via Varejo',
        iconSrc: '/assets/imgs/viavarejo.svg',
        configuration: {},
      },
      {
        name: 'Amazon',
        iconSrc: '/assets/imgs/amazon.svg',
        configuration: {},
      },
      {
        name: 'Shopee',
        iconSrc: '/assets/imgs/shopee.svg',
        authorizationUrl: `${baseURL}/shopee/app/authorization`,
        configuration: {},
      },
    ],
  },
  {
    category: 'Emissor de Notas Fiscais',
    icon: ReceiptOutlined,
    options: [
      {
        key: 'webmaniaBr',
        name: 'Webmania',
        iconSrc: '/assets/imgs/webmania.svg',
        configuration: {
          consumerKey: '',
          consumerSecret: '',
          accessToken: '',
          accessTokenSecret: '',
          naturezaOperacaoVenda: '',
          classeImpostoVenda: '',
          naturezaOperacaoDevolucao: '',
          classeImpostoDevolucao: '',
          naturezaOperacaoConserto: '',
          classeImpostoConserto: '',
          naturezaOperacaoUsoConsumo: '',
          classeImpostoUsoConsumo: '',
          naturezaOperacaoTransferencia: '',
          classeImpostoTransferencia: '',
        },
      },
      {
        key: 'omieApi',
        name: 'Omie',
        iconSrc: '/assets/imgs/omie.svg',
        configuration: {
          appKey: '',
          appSecret: '',
        },
      },
    ],
  },
  {
    category: 'Bancos',
    icon: CurrencyExchangeOutlined,
    options: [
      {
        key: 'bradescoMp',
        name: 'Bradesco',
        iconSrc: '/assets/imgs/bradesco.svg',
        configuration: {
          user: '',
          merchantId: '',
          securityKey: '',
          encryptionKey: '',
        },
      },
      {
        key: 'sicoobApi',
        name: 'Sicoob',
        iconSrc: '/assets/imgs/sicoob.svg',
        configuration: {
          clientId: '',
          pixKey: '',
          contract: '',
          currentAccount: '',
        },
      },
      {
        key: 'mercadoPago',
        name: 'Mercado Pago',
        iconSrc: '/assets/imgs/mercadopago.svg',
        configuration: {
          accessToken: '',
        },
      },
    ],
  },
];

export const newMarkExpressoCity = {
  cityName: '',
  startCep: '',
  endCep: '',
  drivers: [],
};

export const weekDays = [
  {
    name: 'Segunda-feira',
    active: false,
  },
  {
    name: 'Terça-feira',
    active: false,
  },
  {
    name: 'Quarta-feira',
    active: false,
  },
  {
    name: 'Quinta-feira',
    active: false,
  },
  {
    name: 'Sexta-feira',
    active: false,
  },
  {
    name: 'Sábado',
    active: false,
  },
  {
    name: 'Domingo',
    active: false,
  },
];
