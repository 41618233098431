import Axios from 'axios';
import React, { useState } from 'react';
import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
} from '@mui/material';
import dayjs from 'dayjs';
import SimpleDialog from '../../Common/SimpleDialog';
import CustomDatePicker from '../../CustomDatePicker';
import { formatHandleError } from '../../../helpers/formatData';
import CompanyAutocomplete from '../../Common/CompanyAutocomplete';

function ReportInvoiceDialog({
  openReportInvoiceDialog,
  handleCloseReportInvoiceDialog,
  filterModelOptions,
  filterSerieOptions,
  filterOperationOptions,
  filterCompanyOptions,
  filterUserOptions,
  defaultStartDate,
  defaultEndDate,
}) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [reportType, setReportType] = useState('nf-total');
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChangeStartDate = (date) => setStartDate(date);
  const handleChangeEndDate = (date) => setEndDate(date);

  const handleConfirmButton = async () => {
    try {
      setLoading(true);

      if (reportType === 'nf-total') {
        const response = await Axios.get('/invoices/report', {
          params: {
            filterModelOptions,
            filterSerieOptions,
            filterOperationOptions,
            filterCompanyOptions,
            filterUserOptions,
            startDate: dayjs(startDate).startOf('day'),
            endDate: dayjs(endDate).endOf('day'),
          },
        });

        const { url } = response.data;
        window.open(url);
      } else {
        const response = await Axios.post('/invoices/report-stock', {
          startDate: dayjs(startDate).startOf('day'),
          endDate: dayjs(endDate).endOf('day'),
          mergeInAveragePrice: reportType === 'stock-price-average',
          company: company?._id,
        });

        const { url } = response.data;
        window.open(url);
      }

      setLoading(false);
      handleCloseReportInvoiceDialog();
    } catch (error) {
      setLoading(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao baixar relatório das Notas Fiscais',
        error,
      });
    }
  };

  return (
    <SimpleDialog
      openDialog={openReportInvoiceDialog}
      handleClose={handleCloseReportInvoiceDialog}
      dialogTitle="Relatório"
      dialogText="Relatório de notas fiscal em CSV"
      content={
        <Box>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Box width={200}>
                    <CustomDatePicker
                      size="small"
                      label="Data inicial"
                      format="DD/MM/YYYY"
                      value={startDate}
                      onChange={handleChangeStartDate}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={200}>
                    <CustomDatePicker
                      size="small"
                      label="Data final"
                      format="DD/MM/YYYY"
                      value={endDate}
                      onChange={handleChangeEndDate}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl>
                <FormLabel>Escolha o tipo de relatório</FormLabel>
                <RadioGroup
                  value={reportType}
                  onChange={(event) => setReportType(event.target.value)}
                >
                  <FormControlLabel
                    value="nf-total"
                    control={<Radio />}
                    label="Total de notas fiscais"
                  />
                  <FormControlLabel
                    value="stock-movement"
                    control={<Radio />}
                    label="Movimentação de estoque"
                  />
                  <FormControlLabel
                    value="stock-price-average"
                    control={<Radio />}
                    label="Estoque com preço médio"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {reportType !== 'nf-total' ? (
              <Grid item>
                <CompanyAutocomplete
                  handleOnChange={(event, newValue) => setCompany(newValue)}
                  selectedCompany={company}
                />
              </Grid>
            ) : null}
          </Grid>
          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      }
      cancelButtonText="Cancelar"
      confirmButtonText="Gerar arquivo"
      confirmButtonLoading={loading}
      disableConfirmButton={reportType !== 'nf-total' && !company}
      handleConfirmButton={handleConfirmButton}
      handleCancelButton={handleCloseReportInvoiceDialog}
    />
  );
}

export default ReportInvoiceDialog;
