import {
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import CustomPagination from '../../../../../Common/CustomPagination';
import SimpleLinearProgress from '../../../../../Common/SimpleLinearProgress';
import { formatDateWithHour } from '../../../../../../helpers/formatData';

const useStyles = makeStyles(() => ({
  paperTable: {
    height: '47vh',
  },
  tableRowPointer: {
    cursor: 'pointer',
  },
}));

function MovementTable({ handleSelectOrder, movements, limit, page, setPage }) {
  const classes = useStyles();

  const getLabelColorType = (movement) => {
    if (movement.type === 'Entrada') {
      return { bg: '#45046a', color: '#fff' };
    }
    return { bg: '#ffdcb4', color: '#000' };
  };

  return (
    <Paper className={classes.paperTable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Pedido</TableCell>
            <TableCell>Tipo pedido</TableCell>
            <TableCell>Data</TableCell>
            <TableCell align="center">Quantidade</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {movements.stockMovements.map((stockMovement) => (
            <TableRow
              key={stockMovement._id}
              onClick={() => handleSelectOrder(stockMovement)}
              className={stockMovement.sourceId ? classes.tableRowPointer : null}
            >
              <TableCell align="center">
                <Chip
                  label={stockMovement.sourceId}
                  size="small"
                  style={{
                    backgroundColor: getLabelColorType(stockMovement).bg,
                    color: getLabelColorType(stockMovement).color,
                  }}
                />
              </TableCell>
              <TableCell>{stockMovement.sourceType}</TableCell>
              <TableCell>{formatDateWithHour(stockMovement.date)}</TableCell>
              <TableCell align="center">{stockMovement.quantity}</TableCell>
            </TableRow>
          ))}
          {movements.stockMovements.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>
                <SimpleLinearProgress loading={movements.loading} />
                {!movements.loading ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <Typography>Nenhuma movimentação para exibir</Typography>
                    </Grid>
                  </Grid>
                ) : null}
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={4}>
              <CustomPagination
                page={page}
                total={movements.countMovements}
                limit={limit}
                handleChangePage={(_, value) => {
                  setPage(value);
                }}
                showInput={false}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
}

export default MovementTable;
