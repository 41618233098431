import {
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import checkValidCpfCnpj from '../../../../helpers/checkValidCpfCnpj';
import { formatCpfCpnj, onlyNumbersValue } from '../../../../helpers/formatData';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';
import AddressCard from '../../../Users/Details/AddressCard';
import AddressDialog from '../../../Users/Details/AddressDialog';
import BrStatesAutocomplete from '../../../Common/BrStatesAutocomplete';
import brazilianStates from '../../../../constant/brazilianStates';
import CompanyAutocomplete from '../../../Common/CompanyAutocomplete';

const useStyles = makeStyles({
  gridIeSt: {
    position: 'relative',
    padding: 5,
    borderRadius: 5,
    backgroundColor: grey[100],
  },
  gridIeStButtons: {
    position: 'absolute',
    top: 0,
    right: 5,
  },
});

function AddCompanyDialog({
  companyForm,
  setCompanyForm,
  openAddCompanyDialog,
  handleCloseAddCompanyDialog,
  handleConfirmAddCompanyDialog,
  disableConfirmAddCompanyDialog,
}) {
  const classes = useStyles();

  const [ieStForm, setIeStForm] = useState({
    state: '',
    inscricaoEstadual: '',
  });

  const [openAddIeStDialog, setOpenIeStDialog] = useState(false);
  const handleOpenIeStDialog = () => setOpenIeStDialog(true);
  const handleCloseAddIeStDialog = () => setOpenIeStDialog(false);

  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const handleOpenAddressDialog = () => setOpenAddressDialog(true);
  const handleCloseAddressDialog = () => setOpenAddressDialog(false);

  const handleAddIeSt = () => {
    if (!companyForm.ieSt?.find((st) => st.inscricaoEstadual === ieStForm.inscricaoEstadual)) {
      setCompanyForm((oldFields) => ({
        ...oldFields,
        ieSt: [...(oldFields.ieSt || []), ieStForm],
      }));
    }

    handleCloseAddIeStDialog();
    setIeStForm({
      state: '',
      inscricaoEstadual: '',
    });
  };

  const handleDeleteIeSt = ({ deleteIndex }) => {
    const updatedIeSt = companyForm.ieSt.filter((st, index) => index !== deleteIndex);
    return setCompanyForm((oldFields) => ({
      ...oldFields,
      ieSt: updatedIeSt,
    }));
  };

  const handleAddAddress = (fields) => {
    setCompanyForm((oldFields) => ({
      ...oldFields,
      address: {
        ...oldFields.address,
        ...fields.address,
      },
    }));
  };

  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    setCompanyForm((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <SimpleDialog
      openDialog={openAddCompanyDialog}
      handleClose={handleCloseAddCompanyDialog}
      dialogTitle="Adicionar uma empresa"
      dialogText="Adicione uma empresa para começar a utilizar o sistema"
      cancelButtonText="Cancelar"
      confirmButtonText="Continuar"
      handleCancelButton={handleCloseAddCompanyDialog}
      handleConfirmButton={handleConfirmAddCompanyDialog}
      content={
        <Grid container justifyContent="space-between" direction="column" spacing={2}>
          <Grid item>
            <Tooltip title={<Typography align="center">Situação</Typography>}>
              <ButtonGroup variant="contained" size="small">
                <Button
                  onClick={() =>
                    setCompanyForm((oldFields) => ({
                      ...oldFields,
                      active: true,
                    }))
                  }
                  color={companyForm.active ? 'primary' : 'default'}
                >
                  Ativa
                </Button>
                <Button
                  onClick={() =>
                    setCompanyForm((oldFields) => ({
                      ...oldFields,
                      active: false,
                    }))
                  }
                  color={companyForm.active ? 'default' : 'primary'}
                >
                  Inativa
                </Button>
              </ButtonGroup>
            </Tooltip>
          </Grid>
          <Grid item>
            <CustomInput
              label="Nome"
              name="name"
              value={companyForm.name}
              error={!companyForm.name}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CustomInput
              label="CNPJ"
              name="cnpj"
              value={formatCpfCpnj(companyForm.cnpj)}
              error={!checkValidCpfCnpj(companyForm.cnpj)}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CustomInput
              label="Razão Social"
              name="razaoSocial"
              value={companyForm.razaoSocial}
              error={!companyForm.razaoSocial}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CustomInput
              label="Nome Fantasia"
              name="nomeFantasia"
              value={companyForm.nomeFantasia}
              error={!companyForm.nomeFantasia}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CustomInput
              label="Tipo"
              name="type"
              value={companyForm.type}
              error={!companyForm.type}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <Typography align="center">
                  Bloqueia a emissão de NF no último dia do mês após as 16h e libera após as 19h
                </Typography>
              }
            >
              <FormControlLabel
                control={
                  <Switch
                    onClick={() => {
                      setCompanyForm((oldFields) => ({
                        ...oldFields,
                        blockInvoiceOnLastDayMonthAfter4pm:
                          !oldFields.blockInvoiceOnLastDayMonthAfter4pm,
                      }));
                    }}
                    checked={companyForm.blockInvoiceOnLastDayMonthAfter4pm || false}
                    color="primary"
                  />
                }
                label="Bloquear fatura no último dia do mês após as 16h"
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <Typography align="center">
                  Verifica se o produto teve pedido de entrada nesta empresa e bloqueia a emissão de
                  NF caso não tenha.
                </Typography>
              }
            >
              <FormControlLabel
                control={
                  <Switch
                    onClick={() => {
                      setCompanyForm((oldFields) => ({
                        ...oldFields,
                        checkIfStockHasEntry: !oldFields.checkIfStockHasEntry,
                      }));
                    }}
                    checked={companyForm.checkIfStockHasEntry || false}
                    color="primary"
                  />
                }
                label="Verificar se o estoque teve pedido de entrada do produto"
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <Typography align="center">
                  Verifica se o produto tem estoque suficiente nesta empresa e bloqueia a emissão de
                  NF caso não tenha.
                </Typography>
              }
            >
              <FormControlLabel
                control={
                  <Switch
                    onClick={() => {
                      setCompanyForm((oldFields) => ({
                        ...oldFields,
                        checkIfHaveEnoughStock: !oldFields.checkIfHaveEnoughStock,
                      }));
                    }}
                    checked={companyForm.checkIfHaveEnoughStock || false}
                    color="primary"
                  />
                }
                label="Verificar se o produto tem estoque suficiente"
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  onClick={() => {
                    setCompanyForm((oldFields) => ({
                      ...oldFields,
                      automaticStockTransfer: !oldFields.automaticStockTransfer,
                    }));
                  }}
                  checked={companyForm.automaticStockTransfer || false}
                  color="primary"
                />
              }
              label="Emissão automática de NF-e de transferência de estoque"
            />
          </Grid>
          {companyForm.automaticStockTransfer ? (
            <>
              <Grid item>
                <CompanyAutocomplete
                  handleOnChange={(event, newValue) => {
                    setCompanyForm((oldFields) => ({
                      ...oldFields,
                      sourceStockTransfer: newValue,
                    }));
                  }}
                  selectedCompany={companyForm.sourceStockTransfer}
                  label="Empresa de origem do estoque"
                />
              </Grid>
              <Grid item>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <FormControl>
                      <FormLabel>Operação</FormLabel>
                      <RadioGroup
                        row
                        value={companyForm.operationStockTransfer || 1}
                        onChange={(event) => {
                          setCompanyForm((oldFields) => ({
                            ...oldFields,
                            operationStockTransfer: event.target.value,
                          }));
                        }}
                      >
                        <Tooltip
                          title={
                            <Typography align="center">
                              Atenção, esta opção é válida apenas para filiais. Emite de forma
                              automática uma NF-e de transferência de estoque na empresa de origem
                              para esta no momento da emissão da NF-e do pedido.
                            </Typography>
                          }
                        >
                          <FormControlLabel value={1} control={<Radio />} label="Transferência" />
                        </Tooltip>
                        <Tooltip
                          title={
                            <Typography align="center">
                              Emite de forma automática uma NF-e de venda na empresa de origem para
                              esta no momento da emissão da NF-e do pedido.
                            </Typography>
                          }
                        >
                          <FormControlLabel value={2} control={<Radio />} label="Venda" />
                        </Tooltip>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <CustomInput
                      label="Desconto na transferência"
                      name="discountStockTransfer"
                      value={companyForm.discountStockTransfer || ''}
                      onChange={handleChange}
                      variant="outlined"
                      InputEndAdornment="%"
                      number
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
          <Grid item>
            <Grid container justifyContent="space-between" direction="column" spacing={2}>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h6">Inscrições Estaduais</Typography>
                  </Grid>
                  <Grid item>
                    <Button onClick={handleOpenIeStDialog} variant="contained" color="primary">
                      Adicionar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {companyForm.ieSt && companyForm.ieSt.length > 0 ? (
                <Grid item>
                  <Grid container spacing={1}>
                    {companyForm.ieSt.map((st, index) => (
                      <Grid item xs={6} key={`${index + 1}`}>
                        <Grid container className={classes.gridIeSt}>
                          <Grid item xs={3}>
                            <Typography>{st.state}</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography>{st.inscricaoEstadual}</Typography>
                          </Grid>
                          <Grid item className={classes.gridIeStButtons}>
                            <IconButton
                              size="small"
                              onClick={() => handleDeleteIeSt({ deleteIndex: index })}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h6">Endereço</Typography>
          </Grid>
          {companyForm.address && companyForm.address.postalCode ? (
            <Grid item>
              <AddressCard
                address={companyForm.address}
                addressIndex={0}
                handleDeleteAddress={handleOpenAddressDialog}
              />
            </Grid>
          ) : (
            <Grid item onClick={handleOpenAddressDialog}>
              <CustomInput
                label="Endereço"
                name="address"
                value="Informe o endereço"
                variant="outlined"
              />
            </Grid>
          )}

          {openAddIeStDialog ? (
            <SimpleDialog
              openDialog={openAddIeStDialog}
              handleClose={handleCloseAddIeStDialog}
              dialogTitle="Adicionar Inscrição Estadual"
              dialogText="Inscrição Estadual do Substituto Tributário da UF de destino"
              cancelButtonText="Cancelar"
              confirmButtonText="Confirmar"
              handleCancelButton={handleCloseAddIeStDialog}
              handleConfirmButton={handleAddIeSt}
              disableConfirmButton={!(ieStForm.inscricaoEstadual && ieStForm.state)}
              content={
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <BrStatesAutocomplete
                      handleOnChange={(event, newValue) => {
                        if (newValue) {
                          setIeStForm((oldFields) => ({ ...oldFields, state: newValue.initials }));
                        } else {
                          setIeStForm((oldFields) => ({ ...oldFields, state: '' }));
                        }
                      }}
                      selectedState={
                        brazilianStates.find((state) => state.initials === ieStForm.state) || null
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <CustomInput
                      label="Inscrição Estadual"
                      value={ieStForm.inscricaoEstadual}
                      onChange={(event) => {
                        const value = onlyNumbersValue(event.target.value);

                        setIeStForm((oldFields) => ({
                          ...oldFields,
                          inscricaoEstadual: value,
                        }));
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              }
            />
          ) : null}
          {openAddressDialog ? (
            <AddressDialog
              editAddress={
                companyForm.address && companyForm.address.postalCode ? companyForm.address : null
              }
              openDialogAddress={openAddressDialog}
              handleCloseDialogAddress={handleCloseAddressDialog}
              handleAddAddress={handleAddAddress}
            />
          ) : null}
        </Grid>
      }
      disableConfirmButton={disableConfirmAddCompanyDialog}
    />
  );
}

export default AddCompanyDialog;
